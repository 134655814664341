export default [
  // 404页面
  {
    path: '/notFound',
    name: 'notFound',
    component: (resolve) => require(['@/views/common/notFound'], resolve),
  },
  // 合同查看页面
  {
    path: '/protocol',
    name: 'protocol',
    component: (resolve) => require(['@/views/protocol/index'], resolve),
    meta: {
      title: '授权协议',
    },
  },
]
