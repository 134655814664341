import Vue from 'vue'
import VueRouter from 'vue-router'
import clRouter from './clRouter'
Vue.use(VueRouter)

const routes = [...clRouter]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch(err => err)
}
// let pathList = routes.map(v => {
//   return v.path
// })
// router.beforeEach((to, from, next) => {
//   if (pathList.includes(to.path)) {
//     next()
//   } else {
//     next('/notFound')
//   }
// })

export default router
