import Vue from 'vue'
import { Dialog } from 'vant'
import App from './App.vue'
import router from './router'
import store from './store'

import '@/assets/css/common.less'
import '@/assets/css/normalize.css'
import '@/assets/css/reset.less'
import '@/assets/css/vant.less'
import 'vant/lib/index.css'

import './utils/rem'
import i18n from './lang/index' // 图片压缩

import common from '@/utils/common' // 全局方法
import validate from '@/utils/validate' // 全局校验
import { getToken, setToken } from './utils/auth'
import '@/utils/directive'

// 引用js
require('./utils/lrz.all.bundle.js')

Vue.prototype.$common = common
Vue.prototype.$validate = validate

Vue.config.productionTip = false
Vue.config.devtools = true

router.beforeEach((to, from, next) => {
  let { token } = to.query
  if (token) {
    setToken(token)
  } 
  next()
})

router.beforeResolve((to, from, next) => {
  document.title = to.meta.title || '合同h5'
  next()
})

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app')
